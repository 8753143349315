import React from "react";

export const UserContext = React.createContext({
    userId: '',
    setUserId: () => { },
    userToken: '',
    setUserToken: () => { },
    userName: '',
    setUserName: () => { },
    userLastName: '',
    setUserLastName: () => { },
    userImage: '',
    setUserImage: () => { },
    allEnvironments: '',
    setAllEnvironments: () => { },
});

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = React.useState('')
    const [userToken, setUserToken] = React.useState('')
    const [userName, setUserName] = React.useState('')
    const [userLastName, setUserLastName] = React.useState('')
    const [userImage, setUserImage] = React.useState('')
    const [allEnvironments, setAllEnvironments] = React.useState('')
    const val = {
        userId: userId,
        setUserId: (a) => setUserId(a),
        userToken: userToken,
        setUserToken: (a) => setUserToken(a),
        userName: userName,
        setUserName: (a) => setUserName(a),
        userLastName: userLastName,
        setUserLastName: (a) => setUserLastName(a),
        userImage: userImage,
        setUserImage: (a) => setUserImage(a),
        allEnvironments: allEnvironments,
        setAllEnvironments: (a) => setAllEnvironments(a),
    }
    return <UserContext.Provider value={val}>{children}</UserContext.Provider>
}