import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import DashboardHeader from '../components/Dashboard/dashboardHeader/dashboardHeader';
import DashboardSider from '../components/Dashboard/dashboardSIder/dashboardSider';
import './dashboard.css';
import 'antd/dist/antd.css';
import { Switch, Route } from 'react-router-dom';
import OverviewPage from '../components/Analytics/Overview/overviewPage';
import EnvironmentsPage from '../components/Analytics/Environments/environmentPage';
import SideBar from '../components/SideBar/sideBar';

const { Content } = Layout;

export default function Dashboard() {

  const [navVisible, showNavBar] = useState(false);

  return (
    <div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden'}}>
    <SideBar visible={navVisible} show={showNavBar}/>
    <Layout className="layout">
    <DashboardHeader />
      <Layout hasSider={true} style={{ backgroundColor: '#323b44', width: '100%' }}>
        <Layout style={{ padding: '0 24px 0px ', backgroundColor: '#323b44' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Switch>
              <Route
                exact
                path="/:idGateway/overview/"
                component={OverviewPage}
              />
            </Switch>
            <Switch>
              <Route
                exact
                path="/:idGateway/ambiente/:idAmbiente"
                component={EnvironmentsPage}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
      </Layout>
    </div>
  );
}
