import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../../services/api';
import ReactApexChart from 'react-apexcharts';
import {
  calc,
  handleRoundTime,
  handleValueAndTimeLastTwelveHours,
  hoursForComparisons,
} from './holders';

const BarChart = (props) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  let { idGateway } = useParams();
  let { h1, h2, h3, h4, h5, h6, h7, h8, h9, h10, h11, h12 } =
    hoursForComparisons();

  async function getClosure() {
    let date;
    let verifyWasOn;
    let time = [];
    let value = [];
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getOpenClosedBySensorId2(
      props.param2.ieeeAddr,
      idGateway
    );

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['rgba(199, 107, 144, 0.8)'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Aberto por ' + value + ' min';
            } else {
              return 'Fechado';
            }
          },
        },
      },
      fill: {
        colors: ['#c76b90'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getPulse() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getSmartPulse(props.param2.ieeeAddr, idGateway);

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#278a82'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'x';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Acionado ' + value + 'x';
            } else {
              return 'Não foi acionado';
            }
          },
        },
      },
      fill: {
        colors: ['#278a82'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getLampadaF() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getLamp(props.param2.ieeeAddr, idGateway);

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#e0e052'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Ligado por ' + value + ' min';
            } else {
              return 'Desligado';
            }
          },
        },
      },
      fill: {
        colors: ['#e0e052'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getLedF() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getLed(props.param2.ieeeAddr, idGateway);

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#e0e052'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Ligado por ' + value + ' min';
            } else {
              return 'Desligado ';
            }
          },
        },
      },
      fill: {
        colors: ['#e0e052'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getWater() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);

    const res = await Api.getOpenClosedBySensorId2(
      props.param2.ieeeAddr,
      idGateway
    );

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);

      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#6bb5c7'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Com água por ' + value + ' min';
            } else {
              return 'Sem água';
            }
          },
        },
      },
      fill: {
        colors: ['#6bb5c7'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getSireneF() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getSirene(props.param2.ieeeAddr, idGateway);

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#b6566b'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Acionado por ' + value + ' min';
            } else {
              return 'Desligado';
            }
          },
        },
      },
      fill: {
        colors: ['#b6566b'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getSmartLockF() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getSmartLock(props.param2.ieeeAddr, idGateway);

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#df4eba'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Aberto ' + value + 'x';
            } else {
              return 'Fechado';
            }
          },
        },
      },
      fill: {
        colors: ['#df4eba'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getChaveUmQuartoF() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getChaveUmQuarto(props.param2.ieeeAddr, idGateway);

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#e72525'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Acionado ' + value + 'x';
            } else {
              return 'Fechado';
            }
          },
        },
      },
      fill: {
        colors: ['#e72525'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getSmartSwitch() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getSmartSwitchBySensorId2(
      props.param2.ieeeAddr,
      idGateway
    );

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        type: 'column',
        data: value,
      },
    ]);
    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        show: true,
        colors: ['#61ac53'],
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Acionado por ' + value + ' min';
            } else {
              return 'Desligado';
            }
          },
        },
      },
      fill: {
        colors: ['#61ac53'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 1,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  useEffect(() => {
    switch (props.param) {
      case 'openClosed': {
        getClosure();
        const timer = setInterval(() => {
          getClosure();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'water': {
        getWater();
        const timer = setInterval(() => {
          getWater();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'smartPulse': {
        getPulse();
        const timer = setInterval(() => {
          getPulse();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'smartSwitch': {
        getSmartSwitch();
        const timer = setInterval(() => {
          getSmartSwitch();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'smartLock': {
        getSmartLockF();
        const timer = setInterval(() => {
          getSmartLockF();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'sirene': {
        getSireneF();
        const timer = setInterval(() => {
          getSireneF();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'chaveUmQuarto': {
        getChaveUmQuartoF();
        const timer = setInterval(() => {
          getChaveUmQuartoF();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'lampada': {
        getLampadaF();
        const timer = setInterval(() => {
          getLampadaF();
        }, 60000);
        return () => clearInterval(timer);
      }
      case 'led': {
        getLedF();
        const timer = setInterval(() => {
          getLedF();
        }, 60000);
        return () => clearInterval(timer);
      }
    }
  }, []);

  return (
    <div>
      <div>
        <ReactApexChart options={options} series={series} type="bar" />
      </div>
    </div>
  );
};

export default BarChart;
