import React from "react"
import { arc } from "d3-shape"
import { scaleLinear } from "d3-scale"
import { format } from "d3-format"
import {  LoadingOutlined} from '@ant-design/icons';
import './environmentGauge.css'

const EnvironmentGauge = (props) => {
  const backgroundArc = arc()
    .innerRadius(0.9)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(1)
    ()

  const percentScale = scaleLinear()
    .domain([props.min, props.max])
    .range([0, 1])

  const percent = percentScale(props.value)


  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI, Math.PI])
    .clamp(true)

  const angle = angleScale(percent)

  const filledArc = arc()
  .innerRadius(0.9)
  .outerRadius(1)
  .startAngle(-Math.PI / 2)
  .endAngle(angle)
  .cornerRadius(1)
    ()


  return (
    <div className="average" style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      textAlign: "center"
    }}>
      <svg style={{ overflow: "hidden" }}
        width="10rem"
        viewBox={[
          -1, -1,
          2, 1,
        ].join(" ")}>
        <path
          d={backgroundArc}
          fill="#3c4247"
        />
        <path
          d={filledArc}
          fill={(props.value < props.max)? '#00FF7F' :
          '#DC143C'}
        />
      </svg>

      <div style={{
        color: "#FFFFFF",
        marginTop: "-3rem",
        fontSize: "1.3rem",
        lineHeight: "1em",
        fontWeight: "900",
        fontFeatureSettings: "'zero', 'tnum' 1",
      }}>
        {
        props.value == 0 ? < LoadingOutlined/>:
          props.devId === '770' && props.type === 'temp' ? format(".3")(props.value) + 'ºC' :
          props.devId === '770' && props.type === 'hum' ? format(".3")(props.value) + '%' :
          props.devId === '262' ? format(".3")(props.value) + ' lux' :
          props.devId === '81' ? format(".3")(props.value) + ' Kw/h' :
          null}
          {props.devId === '81'?
          <div className="minMax"> min: {format(".3")(props.min/1000)} max: {format(".3")(props.max/1000)}</div> :
          <div className="minMax"> min: {format(".3")(props.min)} max: {format(".3")(props.max)}</div> }
      </div>
      
      {props.devId==='81' ||props.devId==='771'?
      <div className="hide"> <span>Energia</span></div>: 
      props.devId==='770' && props.type === 'temp'?
      <div className="hide"><span>Temperatura</span></div>:
      props.devId==='770' && props.type === 'hum'?
      <div className="hide"><span>Umidade</span></div>:
      props.devId==='262'?
      <div className="hide"><span>Luminosidade</span></div>:
      null
        }
    </div>

  )
}
export default EnvironmentGauge
