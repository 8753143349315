export function hoursForComparisons() {
    let h1 = new Date();
    h1.setHours(h1.getHours() - 12);
    h1.setHours(h1.getHours() + 1);
    let h2 = new Date();
    h2.setHours(h2.getHours() - 12);
    h2.setHours(h2.getHours() + 2);
    let h3 = new Date();
    h3.setHours(h3.getHours() - 12);
    h3.setHours(h3.getHours() + 3);
    let h4 = new Date();
    h4.setHours(h4.getHours() - 12);
    h4.setHours(h4.getHours() + 4);
    let h5 = new Date();
    h5.setHours(h5.getHours() - 12);
    h5.setHours(h5.getHours() + 5);
    let h6 = new Date();
    h6.setHours(h6.getHours() - 12);
    h6.setHours(h6.getHours() + 6);
    let h7 = new Date();
    h7.setHours(h7.getHours() - 12);
    h7.setHours(h7.getHours() + 7);
    let h8 = new Date();
    h8.setHours(h8.getHours() - 12);
    h8.setHours(h8.getHours() + 8);
    let h9 = new Date();
    h9.setHours(h9.getHours() - 12);
    h9.setHours(h9.getHours() + 9);
    let h10 = new Date();
    h10.setHours(h10.getHours() - 12);
    h10.setHours(h10.getHours() + 10);
    let h11 = new Date();
    h11.setHours(h11.getHours() - 12);
    h11.setHours(h11.getHours() + 11);
    let h12 = new Date();
    h12.setHours(h12.getHours() - 12);
    h12.setHours(h12.getHours());

    return {h1, h2, h3, h4, h5, h6, h7, h8, h9, h10, h11, h12}
}

export function calc(
    date,
    on,
    dateOn1,
    dateOn2,
    dateOn3,
    dateOn4,
    dateOn5,
    dateOn6,
    dateOn7,
    dateOn8,
    dateOn9,
    dateOn10,
    dateOn11
) {
    var somaOn = 0;

    if (on.length === 0 && dateOn11?.length > 0 && dateOn11[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn11?.length > 0 && dateOn11[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn10?.length > 0 && dateOn10[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn10?.length > 0 && dateOn10[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn9?.length > 0 && dateOn9[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn9?.length > 0 && dateOn9[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn8?.length > 0 && dateOn8[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn8?.length > 0 && dateOn8[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn7?.length > 0 && dateOn7[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn7?.length > 0 && dateOn7[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn6?.length > 0 && dateOn6[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn6?.length > 0 && dateOn6[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn5?.length > 0 && dateOn5[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn5?.length > 0 && dateOn5[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn4?.length > 0 && dateOn4[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn4?.length > 0 && dateOn4[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn3?.length > 0 && dateOn3[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn3?.length > 0 && dateOn3[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn2?.length > 0 && dateOn2[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn2?.length > 0 && dateOn2[0] === 0) {
    return (somaOn = 0);
    } else if (on.length === 0 && dateOn1?.length > 0 && dateOn1[0] === 1) {
    return (somaOn = 60);
    } else if (on.length === 0 && dateOn1?.length > 0 && dateOn1[0] === 0) {
    return (somaOn = 0);
    } else {
    for (var i = 0; i < date.length; i++) {
        if (i == 0 && on[i] == 1) {
        //se último valor da hora for ligado faz a conta desse último tempo da hora ligado
        var date1 = new Date(date[i]);

        if (date1.getSeconds() > 0)
            dif = 59 - date1.getMinutes() + (60 - date1.getSeconds()) / 60;
        else dif = 60 - date1.getMinutes() + (60 - date1.getSeconds()) / 60;
        somaOn = somaOn + dif;
        }

        if (on[i] == 1 && on[i + 1] == 1)
        //reporte repetido de ligado
        continue;
        if (on[i] == 0 && on[i + 1] == 0)
        //reporte repetido de desligado
        continue;
        if (on[i] == 1)
        // não é para fazer soma
        continue;

        var date1 = new Date(date[i]);
        var date2 = new Date(date[i + 1]);

        var dif = date1 - date2;
        dif = dif / 60000;

        if (i + 1 == date.length) {
        dif = date1.getMinutes() + date1.getSeconds() / 60;
        }
        somaOn = somaOn + dif;
    }
    return Math.ceil(somaOn);
    }
}

export function handleCheckString(hour) {
    if (hour.length === 1) {
    return '0' + String(hour);
    } else {
    return String(hour);
    }
}

// Function to round minutes to 0 or 5
export function handleRoundTime(time) {
    let updatedTime = time.map((hour) => hour.substr(0, 2) + 'h');

    return updatedTime;
}

// Function to check the last values of the last 12 hours, and if you don't have the last 12 hours in the time array, it will insert the missing ones and put the value 0 in the value array
export function handleValueAndTimeLastTwelveHours(
    time = [],
    twelveHoursBefore,
    value = [],
    humidityTrue = false
) {
    let formatTime = [];
    let valuesLastTwelveHours = [];

    if (value.length < 13 || time.length < 13) {
    let comparisonTime =
        twelveHoursBefore.getHours() + 1 === 24
        ? 0
        : twelveHoursBefore.getHours() + 1;
    let includeTimes = time
        .map((hour) => hour.replace('h', ''))
        .map((hour) => Number(hour));

    for (let i = 0; i < 13; i++) {
        if (!(includeTimes.indexOf(comparisonTime) > -1)) {
        includeTimes.push(comparisonTime);
        }
        if (comparisonTime === 23) {
        comparisonTime = 0;
        } else {
        comparisonTime++;
        }
    }

    let greaterThanTwelve = [];
    let lesOrEqualThanTwelve = [];
    function filterHour(hour) {
        if (hour >= 13 && hour <= 23) {
        greaterThanTwelve.push(hour);
        } else {
        lesOrEqualThanTwelve.push(hour);
        }
    }

    includeTimes.filter((hour) => filterHour(hour));

    greaterThanTwelve = greaterThanTwelve.sort((n1, n2) => n1 - n2);
    lesOrEqualThanTwelve = lesOrEqualThanTwelve.sort((n1, n2) => n1 - n2);

    if (new Date().getHours() >= 13 && new Date().getHours() <= 23) {
        includeTimes = lesOrEqualThanTwelve.concat(greaterThanTwelve);
    } else {
        includeTimes = greaterThanTwelve.concat(lesOrEqualThanTwelve);
    }
    formatTime = includeTimes
        .map((hour) => String(hour))
        .map((hour) => handleCheckString(hour))
        .map((hour) => hour.substr(0, 2) + 'h');

    const timeValueIndices = time.map((time) => formatTime.indexOf(time));
    timeValueIndices.reverse();
    value.reverse();
    let ind = 0;

    for (let i = 0; i < 13; i++) {
        if (timeValueIndices.includes(i)) {
        valuesLastTwelveHours[i] = value[ind];
        ind++;
        } else {
        if (humidityTrue && valuesLastTwelveHours[i - 1]) {
            valuesLastTwelveHours[i] = valuesLastTwelveHours[i - 1];
        } else {
            valuesLastTwelveHours[i] = 0;
        }
        }
    }
    } else {
    formatTime = time.reverse();
    valuesLastTwelveHours = value.reverse();
    }

    if (
    formatTime[0] === '00h' &&
    formatTime[11] === '23h' &&
    formatTime[1] !== '01h'
    ) {
    formatTime.push(formatTime.splice(0, 1)[0]);
    valuesLastTwelveHours.push(valuesLastTwelveHours.splice(0, 1)[0]);
    }

    formatTime.splice(-1, 1);
    valuesLastTwelveHours.splice(0, 1);

    const resultFormat = [formatTime, valuesLastTwelveHours];
    return resultFormat;
}