import React, { useContext, useState } from 'react';
import './sideBar.css';
import { RxHamburgerMenu, RxArrowLeft, RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx'
import { Menu, Button, Dropdown, Avatar } from "antd";
import { UserContext } from "../../context/UserContext";
import { Link } from "react-router-dom";
import xicon from '../../xicon.svg';
import logo from '../../logo.png'
import DashboardSider from '../Dashboard/dashboardSIder/dashboardSider';
export default function SideBar({ visible, show }) {

    const [aux, setAux] = useState(false)
    const [barra, setBarra] = useState("")
    const [gatewayName, setGatewayName] = useState([]);
    const user = useContext(UserContext);

    const menu = (
        <Menu className="HeaderMenu">
            <Menu.Item
                onClick={() => {
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    setBarra("/");
                }}
            >
                <Link to={barra}>
                    <span>Sair</span>
                </Link>
            </Menu.Item>
        </Menu>
    )
    return (
        <nav className={'navbar-animation'}>
            <div className={'components-animation'}>
                <button
                    className={'nav-btn'}
                    onClick={() => {
                        show(!visible)
                    }}
                >
                    <img src={xicon} className={'hamburguer-xicon'}></img>
                </button>
                <Dropdown placement='bottomRight' overlay={menu}>
                    <div className='user-btn' style={{ marginBottom: '48px' }}>
                        <Button type="text" className="ButtonUser" style={{ padding: '0px', }}>

                            <Avatar
                                className='user-avatar'
                                src={user.userImage}
                                size={50}
                            ></Avatar>
                            <span className={'user-btn-text'}>
                                Olá, {user.userName}
                            </span>
                        </Button>
                    </div>
                </Dropdown>


                <DashboardSider />

                <div className='return-btn'>
                    <a href='https://ecomfort.com.br'>
                        <Button type="text" className="ButtonUser" style={{ padding: '0px', }} >
                            <RxArrowLeft
                                className='user-avatar'
                                color='white'
                                size={50} />
                            <span className={'user-btn-text'}>
                                Retornar ao <br /> e-Comfort
                            </span>
                        </Button>

                    </a>
                </div>


            </div>
        </nav>
    )
}
