import React, { useContext, useEffect, useState } from "react";
import { Menu, Button, Dropdown, Avatar } from "antd";
import "./header.css";
import logo from "../../../logo.png";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { useHistory } from "react-router-dom";

export default function DashboardHeader() {
  const user = useContext(UserContext);
  const [barra, setBarra] = useState("");

  const menu = (
    <Menu className="HeaderMenu">
      <Menu.Item
        onClick={() => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          setBarra("/");
        }}
      >
        <Link to={barra}>
          <span>Sair</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  function token() {
    localStorage.setItem("token", user.userToken.token);
  }
  return (
    <div className="Header">
      <img className="logo" src={logo} alt="Pixel TI" />
    </div>
  );
}
