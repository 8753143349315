import React from 'react';
import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';


export default function NotFoundPage() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, a página não existe."
      extra={<Button type="primary"><Link to="/">Voltar ao início</Link></Button>}
    />
  )
}