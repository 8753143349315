import axios from 'axios'
import { getToken } from './auth'
import { analyticsAPI, ecomfortAPI } from './services'

export default class Api {

    static async authentication(body) {
        try {
            const res = await axios.post(`${ecomfortAPI}user/auth`, body, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getTemperatureBySensorId(id, idGateway) {
        try {
            const res = await axios
                .get(`${analyticsAPI}gateway/${idGateway}/temperature?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getHumidityBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/humidity?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getIlluminanceBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/illuminance?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getOnOffBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getMeteringBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/metering?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getIntensityBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/intensity?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getOccupancyBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/occupancy?ieeeAddr=${id}&saveTime=1m`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getOpenClosedBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getOpenClosedBySensorId2(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSmartPulse(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=265`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getChaveUmQuarto(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=772`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSirene(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=2`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSmartLock(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=10`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getLed(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=261`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getLamp(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=258`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getWaterBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1h&devId=774&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getWaterBySensorId2(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=774`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSmartPlugPlusBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/metering?ieeeAddr=${id}&saveTime=1h&devId=771&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSmartSwitchBySensorId2(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1m&devId=264&`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSmartSwitchBySensorId(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1h&devId=264&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getTemperatureAverage(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/temperature?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getHumidityAverage(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/humidity?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getIlluminanceAverage(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/illuminance?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getMeteringAverage(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/metering?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getIntensityAverage(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/intensity?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getOccupancyAverage(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/occupancy?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getOpenClosedAverage(id, idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1h&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getWaterAverage(id, idGateway) {

        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1h&devId=774&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSmartPlugPlusAverage(id, idGateway) {

        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/metering?ieeeAddr=${id}&saveTime=1h&devId=771&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSmartSwitchAverage(id, idGateway) {

        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/onOff?ieeeAddr=${id}&saveTime=1h&devId=264&limit=12`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getAllEnvironments(idGateway) {
        try {
            const res = axios
                .get(`${analyticsAPI}gateway/${idGateway}/environment`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getSingleEnvironment(idGateway, idEnvironment) {
        try {
            const res = await axios
                .get(`${ecomfortAPI}gateway/${idGateway}/environment/${idEnvironment}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getDevices(idGateway) {
        try {
            const res = await axios
                .get(`${ecomfortAPI}gateway/${idGateway}/device`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getGateway() {

        try {
            const res = axios
                .get(`${analyticsAPI}gateway`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

    static async getUser(id) {

        try {
            const res = axios
                .get(`${ecomfortAPI}user/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "Authorization": `Bearer ${getToken()}`
                    }
                })
            return res;
        } catch (error) {
            return error;
        }
    }

}