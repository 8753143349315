import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import './overviewPage.css';
import LineChart from '../../Charts/lineChart';
import BarChart from '../../Charts/barChart';
import { Col, Row, Button } from 'antd';
import iluminanceicon from '../../../icons/262_icon.svg';
import pulseicon from '../../../icons/265_alt.svg';
import chaveumquartoicon from '../../../icons/772_alt.svg';
import smartlockicon from '../../../icons/10_alt.svg';
import sireneicon from '../../../icons/2_alt.svg';
import temphumicon from '../../../icons/770_icon.svg';
import meteringicon from '../../../icons/81_icon.svg';
import lampicon from '../../../icons/258_icon.svg';
import occupancyicon from '../../../icons/263_icon.svg';
import closureicon from '../../../icons/12_icon.svg';
import watericon from '../../../icons/774_icon.svg';
import smartplugplusicon from '../../../icons/771_icon.svg';
import smartswitchicon from '../../../icons/264_icon.svg';
import ledicon from '../../../icons/261_icon.svg';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Collapsible from 'react-collapsible';

const OverviewPage = () => {
  let { idGateway } = useParams();
  const [environments, setEnvironments] = useState([]);
  const [done, setDone] = useState(false);

  async function getEnvironment() {
    var environmentsTemp = [];
    var activeEnvironment = [];
    const res = await Api.getAllEnvironments(idGateway);
    if (res.status === 200) {
      res.data.data.map((ambientes) => {
        environmentsTemp.push(ambientes);
      });

      environmentsTemp.map((ambientesComDevice) => {
        ambientesComDevice.devices.map((onlyActives) => {
          if (onlyActives.status === 'ONLINE') {
            activeEnvironment.push(ambientesComDevice);
          }
        });
      });

      const uniqueEnvironments = Array.from(
        new Set(activeEnvironment.map((a) => a.id))
      ).map((id) => {
        return activeEnvironment.find((a) => a.id === id);
      });

      setEnvironments(uniqueEnvironments);
    }

    setDone(true);
  }

  useEffect(() => {
    getEnvironment();
  }, []);

  function conteudo(sensor, props) {
    switch (sensor.devId) {
      case '770': {
        switch (props) {
          case 'temp': {
            return (
              <div className="singleCard">
                <Row>
                  <Col className="label" span={22}>
                    <span style={{ float: 'left' }}>{sensor.name}</span>
                  </Col>
                  <Col className="icon" span={2}>
                    <img style={{ float: 'right' }} src={temphumicon} alt="" />
                  </Col>
                </Row>
                <Row>
                  <Col span={1} className="Gauge">
                    {/* <Gauge
                      param={sensor}
                      param2="temp"
                      min="-30"
                      max="100"
                    ></Gauge> */}
                  </Col>
                  <Col span={22}>
                    <LineChart param="temperature" param2={sensor}></LineChart>
                  </Col>
                </Row>
              </div>
            );
          }
          case 'hum': {
            return (
              <div className="singleCard">
                <Row>
                  <Col className="label" span={22}>
                    <span style={{ float: 'left' }}>{sensor.name}</span>
                  </Col>
                  <Col className="icon" span={2}>
                    <img style={{ float: 'right' }} src={temphumicon} alt="" />
                  </Col>
                </Row>
                <Row>
                  <Col span={1} className="Gauge">
                    {/* <Gauge
                      param={sensor}
                      param2="hum"
                      min="-30"
                      max="100"
                    ></Gauge> */}
                  </Col>
                  <Col span={22}>
                    <LineChart param="humidity" param2={sensor}></LineChart>
                  </Col>
                </Row>
              </div>
            );
          }
        }
      }
      case '262': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={iluminanceicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <LineChart param="illuminance" param2={sensor}></LineChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '81': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={meteringicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <LineChart param="metering" param2={sensor}></LineChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '258': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={lampicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <BarChart param="lampada" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '263': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={occupancyicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <LineChart param="occupancy" param2={sensor}></LineChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '12': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={closureicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1 /*5*/} className="Gauge">
                {/* <Gauge param = {sensor} min="0" max="100" ></Gauge> */}
              </Col>
              <Col span={22 /*19*/}>
                <BarChart param="openClosed" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '10': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={smartlockicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1 /*5*/} className="Gauge">
                {/* <Gauge param = {sensor} min="0" max="100" ></Gauge> */}
              </Col>
              <Col span={22 /*19*/}>
                <BarChart param="smartLock" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '772': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img
                  style={{ float: 'right' }}
                  src={chaveumquartoicon}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col span={1 /*5*/} className="Gauge">
                {/* <Gauge param = {sensor} min="0" max="100" ></Gauge> */}
              </Col>
              <Col span={22 /*19*/}>
                <BarChart param="chaveUmQuarto" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '265': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={pulseicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1 /*5*/} className="Gauge">
                {/* <Gauge param = {sensor} min="0" max="100" ></Gauge> */}
              </Col>
              <Col span={22 /*19*/}>
                <BarChart param="smartPulse" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '2': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={sireneicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1 /*5*/} className="Gauge">
                {/* <Gauge param = {sensor} min="0" max="100" ></Gauge> */}
              </Col>
              <Col span={22 /*19*/}>
                <BarChart param="sirene" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '774': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={watericon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <BarChart param="water" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '771': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img
                  style={{ float: 'right' }}
                  src={smartplugplusicon}
                  alt=""
                />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <LineChart param="smartPlugPlus" param2={sensor}></LineChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '264': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={smartswitchicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <BarChart param="smartSwitch" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
      case '261': {
        return (
          <div>
            <Row>
              <Col className="label" span={22}>
                <span style={{ float: 'left' }}>{sensor.name}</span>
              </Col>
              <Col className="icon" span={2}>
                <img style={{ float: 'right' }} src={ledicon} alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={1} className="Gauge">
                {/* <Gauge param={sensor} min="0" max="100"></Gauge> */}
              </Col>
              <Col span={22}>
                <BarChart param="led" param2={sensor}></BarChart>
              </Col>
            </Row>
          </div>
        );
      }
    }
  }

  function button(sensor, props) {
    switch (sensor.devId) {
      case '770': {
        return [
          <div>
            <Button
              className="chartButton tempButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Sensor de Temperatura'
            >
              {conteudo(sensor, (props = 'temp'))}
            </Button>
          </div>,
          <div>
            <Button
              className="chartButton humButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Sensor de Humidade'
            >
              {conteudo(sensor, (props = 'hum'))}
            </Button>
          </div>,
        ];
      }
      case '262': {
        return (
          <div>
            <Button
              className="chartButton ilumButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Sensor de Luminosidade'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '81': {
        return (
          <div>
            <Button
              className="chartButton meteringButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Smart Plug'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '258': {
        return (
          <div>
            <Button
              className="chartButton intensityButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Lâmpada'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '263': {
        return (
          <div>
            <Button
              className="chartButton occupancyButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Sensor de Presença'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '12': {
        return (
          <div>
            <Button
              className="chartButton closureButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Sensor de Abertura e Fechamento'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '772': {
        return (
          <div>
            <Button
              className="chartButton chaveUmQuartoButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Chave Um Quarto de Volta'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '10': {
        return (
          <div>
            <Button
              className="chartButton smartLockButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Smart Lock'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '2': {
        return (
          <div>
            <Button
              className="chartButton sireneButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Sirene'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '774': {
        return (
          <div>
            <Button
              className="chartButton waterButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Sensor de Água'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '771': {
        return (
          <div>
            <Button
              className="chartButton smartPlugPlusButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Smart Plug Plus'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '264': {
        return (
          <div>
            <Button
              className="chartButton smartSwitchButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Smart Switch'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '265': {
        return (
          <div>
            <Button
              className="chartButton pulseButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Smart Pulse'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
      case '261': {
        return (
          <div>
            <Button
              className="chartButton ledButton"
              style={{ backgroundColor: '#201E1E' }}
              type="link"
              title='Controlador de Fita Led'
            >
              {conteudo(sensor)}
            </Button>
          </div>
        );
      }
    }
  }

  environments.sort(function (bef, aft) {
    return bef.name < aft.name ? -1 : bef.name > aft.name ? 1 : 0;
  });

  const handleRenderGraphics = (ambiente) => {
    return ambiente.devices.map((sensor, index) =>
      sensor.status === 'ONLINE' ? button(sensor, index) : null
    );
  };

  return (
    <div className="div-temp" style={{ height: '100%' }}>
      {done === false ? (
        <ReactLoading id="loading" type={'bars'} color={'white'} />
      ) : (
        <div>
          <div className="div-devices">
            <span className="title">Ambientes:</span>
            {environments.map((ambiente, index) =>
              ambiente.name !== 'GERAL' ? (
                <Collapsible
                  key={index}
                  trigger={ambiente.name}
                  transitionTime={300}
                  triggerOpenedClassName="open"
                >
                  <div className="ambiente" key={index}>
                    <div className="Charts">
                      {handleRenderGraphics(ambiente)}
                    </div>
                  </div>
                </Collapsible>
              ) : null
            )}

            {environments.map((ambiente, index) =>
              ambiente.name === 'GERAL' ? (
                <Collapsible
                  className="ambiente"
                  key={index}
                  trigger="Sem ambiente"
                  triggerOpenedClassName="open"
                >
                  <div className="ambiente" key={index}>
                    <div className="Charts">
                      {handleRenderGraphics(ambiente)}
                    </div>
                  </div>
                </Collapsible>
              ) : null
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewPage;
