import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from './view/dashboard'
import Login from './view/Login/login'
import NotFoundPage from './view/404/NotFoundPage'
import { isAuthenticated } from './services/auth.js'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={() => <Login />}></Route>
      <Route exact path="/:idGateway/:tokenLogin/ecomfort" component={() => <Login />}/>
      <PrivateRoute exact path="/:idGateway/overview" component={() => <Dashboard />} />
      <PrivateRoute exact path="/:idGateway/ambiente/:idAmbiente" component={() => <Dashboard />} />
      <Route exact path="*" component={() => <NotFoundPage />} />
    </Switch>
  </BrowserRouter>
)

export default Routes;