import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import { format, parseISO } from 'date-fns';
import Api from '../../services/api';
import {
  calc,
  handleRoundTime,
  handleValueAndTimeLastTwelveHours,
  hoursForComparisons,
} from './holders';

const LineChart = (props) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  // const [singleEnvironment, setSingleEnvironment] = useState([]);
  let { idGateway } = useParams();

  let { h1, h2, h3, h4, h5, h6, h7, h8, h9, h10, h11, h12 } =
    hoursForComparisons();

  async function getTemperature() {
    let time = [];
    let temperature = [];
    let humidityTrue = true;

    const res = await Api.getTemperatureBySensorId(
      props.param2.ieeeAddr,
      idGateway
    );

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);

    if (res.status === 200) {
      for (const dataObj of res.data.data.slice(0, 12)) {
        let receivedTime = new Date(dataObj.createdAt);
        if (receivedTime >= twelveHoursBefore) {
          temperature.push(dataObj.value);
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
      }
    }

    time = handleRoundTime(time);
    [time, temperature] = handleValueAndTimeLastTwelveHours(
      time,
      twelveHoursBefore,
      temperature,
      humidityTrue
    );

    setSeries([
      {
        name: 'Temperatura',
        data: temperature,
      },
    ]);

    setOptions({
      markers: {
        size: [4, 7],
        colors: '#6b81c7',
        strokeColors: '#7575754e',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
      },
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#6b81c7'],
        curve: 'smooth',
      },
      xaxis: {
        categories: time,
        reversed: true,
      },
      yaxis: {
        tickAmount: 9,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'ºC';
          },
        },
      },
      fill: {
        colors: ['#6b81c7'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getOccupancy() {
    let date;
    let time = [];
    let value = [];
    let verifyWasOn;
    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    const res = await Api.getOccupancyBySensorId(
      props.param2.ieeeAddr,
      idGateway
    );

    function checkDate({ createdAt }) {
      date = new Date(createdAt);
      return date >= twelveHoursBefore;
    }

    if (res.status === 200) {
      if (
        res.data.data[0].wasOn === 1 &&
        new Date(res.data.data[0].createdAt) < twelveHoursBefore
      ) {
        verifyWasOn = 1;
      }

      const dataArr = res.data.data.filter(checkDate);
      // arrays to group the hours left open
      let onHour1 = [];
      let onHour2 = [];
      let onHour3 = [];
      let onHour4 = [];
      let onHour5 = [];
      let onHour6 = [];
      let onHour7 = [];
      let onHour8 = [];
      let onHour9 = [];
      let onHour10 = [];
      let onHour11 = [];
      let onHour12 = [];
      // arrays to group the hours that were closed
      let offHour1 = [];
      let offHour2 = [];
      let offHour3 = [];
      let offHour4 = [];
      let offHour5 = [];
      let offHour6 = [];
      let offHour7 = [];
      let offHour8 = [];
      let offHour9 = [];
      let offHour10 = [];
      let offHour11 = [];
      let offHour12 = [];

      let arrData = [];
      for (let z = 0; z < dataArr.length; z++) {
        arrData.push(new Date(dataArr[z].createdAt));

        if (arrData[z]?.getHours() === h12.getHours()) {
          onHour1.push(dataArr[z].createdAt);
          offHour1.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h1.getHours()) {
          onHour2.push(dataArr[z].createdAt);
          offHour2.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h2.getHours()) {
          onHour3.push(dataArr[z].createdAt);
          offHour3.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h3.getHours()) {
          onHour4.push(dataArr[z].createdAt);
          offHour4.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h4.getHours()) {
          onHour5.push(dataArr[z].createdAt);
          offHour5.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h5.getHours()) {
          onHour6.push(dataArr[z].createdAt);
          offHour6.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h6.getHours()) {
          onHour7.push(dataArr[z].createdAt);
          offHour7.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h7.getHours()) {
          onHour8.push(dataArr[z].createdAt);
          offHour8.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h8.getHours()) {
          onHour9.push(dataArr[z].createdAt);
          offHour9.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h9.getHours()) {
          onHour10.push(dataArr[z].createdAt);
          offHour10.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h10.getHours()) {
          onHour11.push(dataArr[z].createdAt);
          offHour11.push(dataArr[z].wasOn);
        } else if (arrData[z]?.getHours() === h11.getHours()) {
          onHour12.push(dataArr[z].createdAt);
          offHour12.push(dataArr[z].wasOn);
        }
      }

      let hora1 = calc(onHour1, offHour1) > 60 ? 60 : calc(onHour1, offHour1);
      let hora2 =
        calc(onHour2, offHour2, offHour1) > 60
          ? 60
          : calc(onHour2, offHour2, offHour1);
      let hora3 =
        calc(onHour3, offHour3, offHour1, offHour2) > 60
          ? 60
          : calc(onHour3, offHour3, offHour1, offHour2);
      let hora4 =
        calc(onHour4, offHour4, offHour1, offHour2, offHour3) > 60
          ? 60
          : calc(onHour4, offHour4, offHour1, offHour2, offHour3);
      let hora5 =
        calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4) > 60
          ? 60
          : calc(onHour5, offHour5, offHour1, offHour2, offHour3, offHour4);
      let hora6 =
        calc(
          onHour6,
          offHour6,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5
        ) > 60
          ? 60
          : calc(
              onHour6,
              offHour6,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5
            );
      let hora7 =
        calc(
          onHour7,
          offHour7,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6
        ) > 60
          ? 60
          : calc(
              onHour7,
              offHour7,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6
            );
      let hora8 =
        calc(
          onHour8,
          offHour8,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7
        ) > 60
          ? 60
          : calc(
              onHour8,
              offHour8,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7
            );
      let hora9 =
        calc(
          onHour9,
          offHour9,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8
        ) > 60
          ? 60
          : calc(
              onHour9,
              offHour9,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8
            );
      let hora10 =
        calc(
          onHour10,
          offHour10,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9
        ) > 60
          ? 60
          : calc(
              onHour10,
              offHour10,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9
            );
      let hora11 =
        calc(
          onHour11,
          offHour11,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10
        ) > 60
          ? 60
          : calc(
              onHour11,
              offHour11,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10
            );
      let hora12 =
        calc(
          onHour12,
          offHour12,
          offHour1,
          offHour2,
          offHour3,
          offHour4,
          offHour5,
          offHour6,
          offHour7,
          offHour8,
          offHour9,
          offHour10,
          offHour11
        ) > 60
          ? 60
          : calc(
              onHour12,
              offHour12,
              offHour1,
              offHour2,
              offHour3,
              offHour4,
              offHour5,
              offHour6,
              offHour7,
              offHour8,
              offHour9,
              offHour10,
              offHour11
            );

      value = [
        hora1,
        hora2,
        hora3,
        hora4,
        hora5,
        hora6,
        hora7,
        hora8,
        hora9,
        hora10,
        hora11,
        hora12,
      ];

      let verifyValue = false;

      for (let i = 0; i < value.length; i++) {
        if (value[i] !== 0) verifyValue = true;
      }

      if (verifyValue === false && verifyWasOn === 1) {
        for (let i = 0; i < value.length; i++) {
          value[i] = 60;
        }
      }

      time = handleRoundTime(time);
      [time] = handleValueAndTimeLastTwelveHours(time, twelveHoursBefore);
    } else {
      time = handleRoundTime(time);
      [time, value] = handleValueAndTimeLastTwelveHours(
        time,
        twelveHoursBefore,
        value
      );
    }

    setSeries([
      {
        name: 'Situação',
        data: value,
      },
    ]);

    setOptions({
      markers: {
        size: [4, 7],
        colors: 'rgb(176, 107, 199)',
        strokeColors: '#7575754e',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
      },
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['rgb(176, 107, 199)'],
        curve: 'smooth',
      },
      xaxis: {
        categories: time,
        reversed: true,
      },
      yaxis: {
        tickAmount: 12,
        min: 0,
        max: 60,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'min';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            if (value > 0) {
              return 'Houve presença (em média) por ' + value + 'min';
            } else {
              return 'Não houve presença';
            }
          },
        },
      },
      fill: {
        colors: ['rgba(176, 107, 199, 0.8)'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getHumidity() {
    let time = [];
    let humidity = [];
    let humidityTrue = true;

    const res = await Api.getHumidityBySensorId(
      props.param2.ieeeAddr,
      idGateway
    );

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);

    if (res.status === 200) {
      for (const dataObj of res.data.data.slice(0, 12)) {
        let receivedTime = new Date(dataObj.createdAt);

        if (receivedTime >= twelveHoursBefore) {
          humidity.push(dataObj.value);
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
      }
    }

    time = handleRoundTime(time);
    [time, humidity] = handleValueAndTimeLastTwelveHours(
      time,
      twelveHoursBefore,
      humidity,
      humidityTrue
    );

    setSeries([
      {
        name: 'Umidade',
        data: humidity,
      },
    ]);

    setOptions({
      markers: {
        size: [4, 7],
        colors: '#6b81c7',
        strokeColors: '#7575754e',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
      },
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#6b81c7'],
        curve: 'smooth',
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        min: 0,
        max: 100,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + '%';
          },
        },
      },
      fill: {
        colors: ['#6b81c7'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getIlluminance() {
    let time = [];
    let illuminance = [];

    const res = await Api.getIlluminanceBySensorId(
      props.param2.ieeeAddr,
      idGateway
    );

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);

    if (res.status === 200) {
      for (const dataObj of res.data.data.slice(0, 12)) {
        let receivedTime = new Date(dataObj.createdAt);
        if (receivedTime >= twelveHoursBefore) {
          illuminance.push(dataObj.value);
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
      }
    }

    time = handleRoundTime(time);
    [time, illuminance] = handleValueAndTimeLastTwelveHours(
      time,
      twelveHoursBefore,
      illuminance
    );

    setSeries([
      {
        name: 'Luminosidade',
        data: illuminance,
      },
    ]);
    setOptions({
      markers: {
        size: [4, 7],
        colors: '#eeb89d',
        strokeColors: '#7575754e',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
      },
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#eeb89d'],
        curve: 'smooth',
      },
      xaxis: {
        type: 'timestamp',
        categories: time,
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: undefined,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'lux';
          },
        },
      },
      fill: {
        colors: ['#eeb89d'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getMetering() {
    let time = [];
    let metering = [];

    const res = await Api.getMeteringBySensorId(
      props.param2.ieeeAddr,
      idGateway
    );

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);

    if (res.status === 200) {
      for (const dataObj of res.data.data.slice(0, 12)) {
        let receivedTime = new Date(dataObj.createdAt);
        if (receivedTime >= twelveHoursBefore) {
          metering.push(parseInt(dataObj.usageValue));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
      }
    }

    time = handleRoundTime(time);
    [time, metering] = handleValueAndTimeLastTwelveHours(
      time,
      twelveHoursBefore,
      metering
    );

    setSeries([
      {
        name: 'Energia',
        data: metering,
      },
    ]);

    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#66cc66'],
        curve: 'smooth',
      },
      xaxis: {
        categories: time,
      },
      yaxis: {
        tickAmount: 6,
        max: undefined,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'w/h';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return value + ' w/h';
          },
        },
      },
      fill: {
        colors: ['#66cc66'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getIntensity() {
    let time = [];
    let intensity = [];

    const res = await Api.getIntensityBySensorId(
      props.param2.ieeeAddr,
      idGateway
    );

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);

    if (res.status === 200) {
      for (const dataObj of res.data.data.slice(0, 12)) {
        let receivedTime = new Date(dataObj.createdAt);
        if (receivedTime >= twelveHoursBefore) {
          intensity.push(parseInt(dataObj.value));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
      }
    }

    time = handleRoundTime(time);
    [time, intensity] = handleValueAndTimeLastTwelveHours(
      time,
      twelveHoursBefore,
      intensity
    );

    setSeries([
      {
        name: 'Intensidade',
        data: intensity,
      },
    ]);

    setOptions({
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#e0e052'],
        curve: 'smooth',
      },
      xaxis: {
        categories: time,
        flexDirection: 'column-reverse',
      },
      yaxis: {
        tickAmount: 6,
        min: 0,
        max: 100,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + '%';
          },
        },
      },
      fill: {
        colors: ['#e0e052'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  async function getSmartPlugPlus() {
    let time = [];
    let metering = [];

    const res = await Api.getSmartPlugPlusBySensorId(
      props.param2.ieeeAddr,
      idGateway
    );

    const currentTime = new Date();
    const twelveHoursBefore = new Date();
    twelveHoursBefore.setHours(currentTime.getHours() - 13);
    if (res.status === 200) {
      for (const dataObj of res.data.data.slice(0, 12)) {
        let receivedTime = new Date(dataObj.createdAt);
        if (receivedTime >= twelveHoursBefore) {
          metering.push(parseInt(dataObj.usageValue));
          time.push(format(parseISO(dataObj.createdAt), "HH'h'mm"));
        }
      }
    }

    time = handleRoundTime(time);
    [time, metering] = handleValueAndTimeLastTwelveHours(
      time,
      twelveHoursBefore,
      metering
    );

    setSeries([
      {
        name: 'Energia',
        data: metering,
      },
    ]);

    setOptions({
      markers: {
        size: [4, 7],
        colors: '#4ef997',
        strokeColors: '#7575754e',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
      },
      grid: {
        strokeDashArray: 0,
        position: 'back',
        borderColor: '#b5bbb645',
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#4ef997'],
        curve: 'smooth',
      },
      xaxis: {
        categories: time,
        flexDirection: 'column-reverse',
      },
      yaxis: {
        tickAmount: 6,
        min: 0,
        max: undefined,
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            let value = parseFloat(val.toFixed(2));
            return value + 'w/h';
          },
        },
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return value + ' w/h';
          },
        },
      },
      fill: {
        colors: ['#4ef997'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.8,
          opacityTo: 0.2,
        },
      },
      noData: {
        text: 'Sem dados no momento...',
        style: {
          color: '#ffffff',
        },
      },
      chart: {
        foreColor: '#838181',
      },
    });
  }

  useEffect(() => {
    switch (props.param) {
      case 'temperature': {
        getTemperature();
        const timer = setInterval(() => {
          getTemperature();
        }, 300000);
        return () => clearInterval(timer);
      }
      case 'occupancy': {
        getOccupancy();
        const timer = setInterval(() => {
          getOccupancy();
        }, 300000);
        return () => clearInterval(timer);
      }

      case 'humidity': {
        getHumidity();
        const timer = setInterval(() => {
          getHumidity();
        }, 300000);
        return () => clearInterval(timer);
      }
      case 'illuminance': {
        getIlluminance();
        const timer = setInterval(() => {
          getIlluminance();
        }, 300000);
        return () => clearInterval(timer);
      }
      case 'metering': {
        getMetering();
        const timer = setInterval(() => {
          getMetering();
        }, 300000);
        return () => clearInterval(timer);
      }

      case 'intensity': {
        getIntensity();
        const timer = setInterval(() => {
          getIntensity();
        }, 300000);
        return () => clearInterval(timer);
      }

      case 'smartPlugPlus': {
        getSmartPlugPlus();
        const timer = setInterval(() => {
          getSmartPlugPlus();
        }, 300000);
        return () => clearInterval(timer);
      }
    }
  }, []);

  return (
    <div>
      <div>
        <ReactApexChart options={options} series={series} type="area" />
      </div>
    </div>
  );
};

export default LineChart;
